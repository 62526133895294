<template>
<b-card v-if="lessons" no-body class="card-company-table">
    <table class="table b-table" v-if="lessons">
        <thead>
            <tr>
                <th scope="col" v-for="field in fields" :key="field.key">
                    {{ field.label }}
                </th>
            </tr>
        </thead>

        <draggable v-model="lessons" tag="tbody" handle=".handle">
            <tr v-for="(item, index) in lessons" :key="item.id">
                <td>
                    <feather-icon icon="MoveIcon" class="handle" />
                </td>
                <td>
                    <span class="font-weight-bolder mb-12">#{{ index + 1 }}</span>
                </td>
                <td>
                    <span class="font-weight-bolder mb-12">{{
              item.createdAt | moment("DD.MM.YYYY hh:mm")
            }}</span>
                </td>
                <td>
                    <b-badge pill :variant="item.isActive ? 'light-success' : 'light-danger'">
                        {{ item.isActive ? "AKTİF" : "PASİF" }}
                    </b-badge>
                </td>
                <td>
                    <span class="font-weight-bolder mb-12">{{ item.code }}</span>
                </td>
                <td>
                    <span class="font-weight-bolder mb-12">{{ item.name }}</span>
                </td>
                <td>
                    <b-button variant="flat-primary" @click="openEdit(item)" v-if="item.originalRef=='00000000-0000-0000-0000-000000000000'">
                        Düzenle
                    </b-button>
                     <b-button variant="flat-danger" @click="deleteLesson(item)" v-if="item.originalRef=='00000000-0000-0000-0000-000000000000'">
                        Sil
                    </b-button>
                </td>
            </tr>
        </draggable>
    </table>

    <!--Open Add Branch-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          ">
                <h5 class="mb-0">Yeni Ders Ekle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <!-- Code -->
                <b-form-group label="Kodu" label-for="from">
                    <b-form-input id="from" type="text" v-model="addClassRequest.code" />
                </b-form-group>

                <!-- Name -->
                <b-form-group label="Adı" label-for="to">
                    <b-form-input id="to" trim type="text" v-model="addClassRequest.name" />
                </b-form-group>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addBranch">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>

    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="editPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          ">
                <h5 class="mb-0">Düzenle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <!-- Code -->
                <b-form-group label="Kodu" label-for="from">
                    <b-form-input id="from" type="text" v-model="editClassRequest.code" />
                </b-form-group>

                <!-- Name -->
                <b-form-group label="Adı" label-for="to">
                    <b-form-input id="to" trim type="text" v-model="editClassRequest.name" />
                </b-form-group>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="editLesson">
                        Güncelle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>
</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import draggable from "vuedraggable";
export default {
    components: {
        draggable,
        ToastificationContent,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
    },
    data() {
        return {
            fields: [
                { key: "viewOrder", label: "Sırlama" },
                { key: "ref", label: "Ref" },
                { key: "createdAt", label: "Kayıt Tarihi" },
                { key: "isActive", label: "Durum" },
                { key: "code", label: "Kodu" },
                { key: "name", label: "Adı" },
                { key: "action", label: "İşlemler" },
            ],
            lessons: [],
            addPanel: false,
            editPanel: false,
            addClassRequest: {
                code: "",
                name: "",
            },
            editClassRequest: {
                id: "",
                code: "",
                name: "",
            },
        };
    },
    created() {
        this.getData();
    },
    mounted() {
        this.$root.$on("AddButtonClick", () => {
            //console.log("Add Button Call");
            this.addPanel = true;
        });
        this.$root.$on("PreviewSaveClick", async () => {
            var index = 0;
            var request = [];
            this.lessons.forEach((element) => {
                element.viewOrder = index;
                request.push({
                    refId: element.id,
                    viewOrder: element.viewOrder,
                });
                index++;
            });
            await this.$http.put("Branch/LessonPreview", request);
            //console.log(request);
        });
    },
    methods: {
        async getData() {
            var lessons = await this.$http.get("Branch/Lessons");
            this.lessons = lessons.data.data;
        },
        async addBranch() {
            await this.$http.post("Branch/AddLesson", this.addClassRequest);
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Bildirim",
                    icon: "BellIcon",
                    text: "İşlem Başarılı.",
                    variant: "success",
                },
            });
            this.addPanel = false;
            this.getData();
        },
        openEdit(item) {
            this.editClassRequest = {
                id: item.id,
                code: item.code,
                name: item.name,
            };
            this.editPanel = true;
        }, 
        async editLesson(){
            await this.$http.put("Branch/UpdateLesson", this.editClassRequest);
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Bildirim",
                    icon: "BellIcon",
                    text: "İşlem Başarılı.",
                    variant: "success",
                },
            });
            this.editPanel = false;
            this.getData();
        },
        async deleteLesson(item){
                     this.$confirm({
                title: 'Sil',
                message: `Silmek İstediğinizden Emin misiniz?`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Sil'
                },
                callback: async (confirm) => {
                    if (confirm) {
                       this.$http.delete("Branch/DeleteLesson/"+item.id).then(data=>{
                           this.getData();
                       }).catch(error=>{
                           alert(error.response?.data.error.message);
                       })
                    }
                }
            });
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
